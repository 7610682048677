/**
 * Created by madura on 11/29/16.
 */
import React, { Component } from 'react';
import { Button, Panel, Image } from 'react-bootstrap';
import Avatar from 'react-avatar';
import Shoutout from './../../services/Shoutout';
import { filter, isEmpty, map, chain } from 'lodash';
import UserAddPoints from './UserAddPoints';
import UserRedeemPoints from './UserRedeemPoints';
import UserRedeemRewards from './UserRedeemReward';
import PropTypes from 'prop-types';
import { notify } from 'react-notify-toast';
import Constants from './../../Constants';

class UserProfile extends Component {
    constructor() {
        super();
        this.state = { showAddPoints: false, showRedeemPoints: false, showRedeemReward: null, user: null, selectedRewardData: null, isSendingRewardRedeemRequest: null };
        this._showAddPoints = this._showAddPoints.bind(this);
        this._closeAddPoints = this._closeAddPoints.bind(this);
        this._showRedeemPoints = this._showRedeemPoints.bind(this);
        this._closeRedeemPoints = this._closeRedeemPoints.bind(this);
        this._showRedeemReward = this._showRedeemReward.bind(this);
        this._closeRedeemReward = this._closeRedeemReward.bind(this);
    }

    _showAddPoints() {
        this.setState({ showAddPoints: true });
    }

    _closeAddPoints(newUserDetails) {
        this.setState({ showAddPoints: false });
        if (newUserDetails) {
            const updatedUserObj = { ...this.props.user, ...this.state.user, ...newUserDetails };
            this.props.updateUser(updatedUserObj);
            this.setState({ user: updatedUserObj });
        }
    }

    _showRedeemPoints() {
        this.setState({ showRedeemPoints: true });
    }

    _closeRedeemPoints(newUserDetails) {
        this.setState({ showRedeemPoints: false });
        if (newUserDetails && newUserDetails.points) {
            const updatedUserObj = { ...this.props.user, ...this.state.user };
            updatedUserObj.points = newUserDetails.points;
            this.props.updateUser(updatedUserObj);
            this.setState({ user: updatedUserObj });
        }
    }

    _showRedeemReward(e) {
        this.setState({ showRedeemReward: e.target.dataset.id });
    }

    loadUser = () => {
        Shoutout.getUser(this.props.user.loyalty_id).then(res => {
            this.setState({ user: res });
        });
    }
    _closeRedeemReward() {
        this.setState({ selectedRewardData: null });
        this.loadUser();
    }

    componentDidMount() {
        this.loadUser();
    }

    _getTierInfo(tierName, tierPoints) {
        let config = this.context.config || {};
        let userTier;
        if (!isEmpty(config, 'tiers.data')) {
            userTier = chain(config.tiers.data).values().find((item) => {
                return item.name === tierName;
            }).value();

        }

        return (tierName ?
            <Panel className="text-center">
                {userTier ? (<div>

                    <Image src={userTier.imageUrl} width={100} /><br /><br />
                    <h4 className="media-heading">{tierName}</h4>
                    <h4 className="">{tierPoints}</h4> <span className="text-muted">Tier Points</span>
                    <br />
                    <br />
                    <div className="text-center">
                        <ul className="list-group list-group-flush">
                            <h5 className="media-heading"><strong>Tier Benefits</strong></h5>
                            {map(userTier.benefits, (item, index) => {
                                return (<li key={`ben-${index}`} className="list-group-item">
                                    <small>{item}</small>
                                </li>)
                            })}
                        </ul>
                    </div>
                </div>) : null
                }
            </Panel> : null);
    }

    requestRedeemReward = async (e) => {
        const rewardId = e.target.dataset.id;
        if (rewardId) {
            try {
                const reward = this.props.rewards.filter(({ _id }) => _id === rewardId)[0];

                this.setState({ isSendingRewardRedeemRequest: rewardId });
                const requestRedeemResponse = await Shoutout.redeemRewardRequest(this.props.user.loyalty_id, rewardId, this.props.selectedBranch);

                if (requestRedeemResponse.otpToken) {
                    this.setState({ selectedRewardData: { rewardId, otpToken: requestRedeemResponse.otpToken, ...reward }, isSendingRewardRedeemRequest: null });
                } else {//admin
                    notify.show("Reward redeemed successfully. Ask customer to provide the coupon code.", "success");
                    this.setState({ isSendingRewardRedeemRequest: null });
                }
            } catch (e) {
                this.setState({ isSendingRewardRedeemRequest: null });
                notify.show("Can not request this reward at the moment. Try later!", "error");
            }
        }
    }

    render() {
        const { user: userProps, rewards, onClickBack, scopes = {} } = this.props;
        const user = this.state.user ? this.state.user : userProps;
        const eligibleRewards = filter(rewards, item => {
            return item.points <= user.points;
        });


        const { collectPoint = true, redeemPoint = true } = this.context.config.klipConfig || {};

        return (
            <div className="user-profile">
                <div className="row">
                    <div className="col-md-4">
                        <div className="text-center">
                            <Button bsStyle="default" bsSize="large" block onClick={onClickBack}>Back</Button>
                        </div>
                    </div>
                    {((scopes[Constants.USER_ACCESS_CONTROLS.MODULES.KLIP_ADD_POINTS.moduleId].write) && collectPoint) && <div className="col-md-4">
                        <div className="text-center">
                            <Button bsStyle="success" bsSize="large" block onClick={this._showAddPoints}>Add Points</Button>
                        </div>
                    </div>
                    }
                    {((scopes[Constants.USER_ACCESS_CONTROLS.MODULES.KLIP_REDEEM_POINTS.moduleId].write) && redeemPoint) &&

                        <div className="col-md-4">
                            <div className="text-center">
                                <Button bsStyle="primary" bsSize="large" block onClick={this._showRedeemPoints} >Redeem Points</Button>
                            </div>
                        </div>
                    }
                </div>




                <br />
                <div className="row">

                    <div className="col-md-4">
                        <div className="card shadow">
                            <div className="card-body">
                                <div className="text-center">
                                    <Avatar name={(user.name || 'Unknown').charAt(0)} round={true} size={80} />
                                    <div className="">
                                        <h4>{user.name}</h4>
                                        <h5>{user.user_id || user.loyalty_id}</h5>
                                        <h2><strong>{user.points}</strong></h2><span className="text-muted">Redeemable Points</span>
                                    </div>
                                    <br />
                                    {this._getTierInfo(user.tier, user.tier_points)}
                                </div>
                            </div>
                        </div>
                    </div>

                    {((scopes[Constants.USER_ACCESS_CONTROLS.MODULES.KLIP_REWARD_REDEEM.moduleId].read) && redeemPoint) &&
                        <div className="col-md-8">
                            <div className="row">
                                {eligibleRewards.length ?
                                    eligibleRewards.map(item => {

                                        return (

                                            <div className="card shadow bg-light col-md-3 card-rewards" key={item._id}>
                                                <div className="card-body card-rewards-body">
                                                    <div className="">
                                                        <Image src={item.imageUrls[0]} className="img-fluid" />
                                                    </div>
                                                    <div className="text-center mt-3 margin-5">
                                                        <h5 className="">{item.name}</h5>
                                                        <p>{item.points} points</p>
                                                        {(scopes[Constants.USER_ACCESS_CONTROLS.MODULES.KLIP_REWARD_REDEEM.moduleId].write) && <div className="margin-5">
                                                            {
                                                                (item.totalCount && item.totalRedeemedCount) ?
                                                                    <div>
                                                                        <Button bsStyle="primary" bsSize="large" data-id={item._id} onClick={this.requestRedeemReward}
                                                                            disabled={this.state.isSendingRewardRedeemRequest !== null || (item.totalCount - item.totalRedeemedCount) <= 0 || !this.props.selectedBranch}>{this.state.isSendingRewardRedeemRequest === item._id ? "Sending..." : "Redeem"}</Button>
                                                                        <br /> <br />
                                                                        <div>Left: {item.totalCount - item.totalRedeemedCount}</div>
                                                                    </div> :
                                                                    <div>
                                                                        <Button bsStyle="primary" bsSize="large" data-id={item._id} onClick={this.requestRedeemReward}
                                                                            disabled={this.state.isSendingRewardRedeemRequest !== null || !this.props.selectedBranch}>{this.state.isSendingRewardRedeemRequest === item._id ? "Sending..." : "Redeem"}</Button>
                                                                        <br /> <br />
                                                                    </div>
                                                            }
                                                            {/* <Button bsStyle="primary" bsSize="large" data-id={item._id} onClick={this.requestRedeemReward} disabled={this.state.isSendingRewardRedeemRequest !== null}>{this.state.isSendingRewardRedeemRequest === item._id ? "Sending..." : "Redeem"}</Button>
                                                            <br /> <br />
                                                            {item.totalCount && item.totalRedeemedCount && <div>Left: {item.totalCount - item.totalRedeemedCount}</div>} */}
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })
                                    :
                                    <div className="text-center custom-margin-top">
                                        <h2> No eligible rewards</h2>
                                    </div>
                                }
                            </div>
                        </div>}

                </div>

                <UserAddPoints show={this.state.showAddPoints} onClose={this._closeAddPoints}
                    loyaltyId={user.loyalty_id} />
                <UserRedeemPoints show={this.state.showRedeemPoints} onClose={this._closeRedeemPoints}
                    loyaltyId={user.loyalty_id} />
                <UserRedeemRewards show={this.state.selectedRewardData !== null} onClose={this._closeRedeemReward}
                    user={user} rewardData={this.state.selectedRewardData} onRedeemed={this.props.loadRewards} selectedBranch={this.props.selectedBranch} />
            </div>
        );
    }
}

UserProfile.contextTypes = {
    config: PropTypes.object
};
export default UserProfile;