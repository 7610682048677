/**
 * Created by madura on 11/23/16.
 */
import {notify} from 'react-notify-toast';
 const accountApiUrl="https://api.getshoutout.com/authservice/";

class AccountService {
    login(email,password){
        const url=accountApiUrl+"login?loginMethod=email";
        const body=JSON.stringify({
            username: email,
            password: password,
        });
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: body
            }).then(function(response) {
                return response.json();
            }).then(function(json) {
                console.log('parsed json', json);
                notify.show('Logged in','success');
                resolve(json);
            }).catch(function(ex) {
                console.log('parsing failed', ex);
                notify.show('Invalid credentials','error');
                reject(ex);
            });
        });
    }
}

export default AccountService;