/**
 * Created by madura on 11/23/16.
 */
import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';

import SignupForm from './signup/SignupForm';
import LoginForm from './login/LoginForm';
import Logout from './logout/Logout';

import './AccountPage.css';

export class AccountPage extends Component {


    //TODO: add loader
    render() {
            const {loadConfig}=this.props;
            console.debug(this.props);
        return (
            <div className="container account-container">
                    
                    <Switch>
                    <Route path="/logout" component={Logout}></Route>
       
                        <div className="row height-inherit">
                            <div className="col-md-3"></div>
                            <div className="col-md-6 my-auto height-inherit">
                                <Route  path="/signup" component={SignupForm}></Route>
                                <Route path="/login" render={(props) => 
                                <LoginForm {...props} loadConfig={loadConfig}/>
                                    } 
                                />

                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </Switch>

            </div>
        );
    }
}
