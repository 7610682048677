import React, { PureComponent } from 'react';
import ShoutoutLoyaltyService from './../../services/ShoutoutLoyaltyService';
import { notify } from 'react-notify-toast';
import FormView from './../forms/FormView';
import PropTypes from 'prop-types';
import UserRedeemValidation from './UserRedeemValidation';

const defaultForm = {
    "form": {
        "jsonschema": {
            "type": "object",
            "title": "",

            "properties": {
                "nic": {
                    "type": "string",
                    "title": "NIC"
                },
                "points": {
                    "type": "number",
                    "title": "Points"
                },
                "note": {
                    "type": "string",
                    "title": "Notes"
                }
            },
            "required": [
                "nic",
                "points"
            ]
        },
        "uischema": {
            "ui:order": [
                "nic",
                "points",
                "note"
            ],
            "nic": {
                "ui:placeholder": "900203200V"
            },
            "points": {
                "ui:placeholder": "10"
            },
            "note": {
                "ui:placeholder": "Bill No: 26362"
            }
        }
    },
    "metadata": {
        "validation": {
            "attrName": "nic"
        }
    }
};
class UserRedeemPoints extends PureComponent {
    constructor() {
        super();
        this._onSubmit = this._onSubmit.bind(this);
        this.state = { showRedeemValidation: false, otpToken: '', formData: {} };

        this.onConfirmClose = this.onConfirmClose.bind(this);
    }

    async _onSubmit(result, callback) {
        try {
            let formDataObj = result.formData;
            const config = this.context.config || {};
            const form = config.pointRedeemForm;
            let validation;
            if (form) {
                if (form.metadata && form.metadata.validation) {
                    validation = form.metadata.validation;
                }
            } else {
                validation = defaultForm.metadata.validation;
            }

            if (validation) {
                validation.attrVal = formDataObj[validation.attrName];
            }

            const redeemPointResponse = await ShoutoutLoyaltyService.redeemPoint(this.props.loyaltyId, formDataObj);

            if (redeemPointResponse.otpToken) {

                this.setState({ showRedeemValidation: true, otpToken: redeemPointResponse.otpToken, formData: formDataObj });


            } else {//admin user. OTP validation skip
                notify.show("Point redeemed successfully", "success");
                this.setState({ showRedeemValidation: false, otpToken: '', formData: {} });
                this.props.onClose({ points: redeemPointResponse.balance_points });
            }
            // Shoutout.addOrRedeemPoints({ userId: this.props.loyaltyId.toString(), activityData: formDataObj, validation }, "redeem").then(res => {

            //     notify.show("Points redeemed successfully", "success");
            //     callback(null, res);
            //     this.props.onClose({ points: res.points });
            // }, err => {
            //     notify.show("Could not redeem points. Please check provided data", "error");
            //     callback("Could not redeem points. Please check provided data");
            // })

        } catch (e) {
            notify.show("Point redeem failed. Please check the details.", "error");
        }
    }

    onConfirmClose(result) {
        this.setState({ showRedeemValidation: false, otpToken: '', formData: {} });
        this.props.onClose({ points: result.balance_points });

    }

    render() {
        const { show, onClose } = this.props;

        if (this.state.showRedeemValidation) {
            return (<UserRedeemValidation show={this.state.showRedeemValidation} onClose={this.onConfirmClose} otpToken={this.state.otpToken} loyaltyId={this.props.loyaltyId}
                formData={this.state.formData} />)
        }
        const config = this.context.config || {};
        const form = config.pointRedeemForm;
        return (
            <FormView form={form || defaultForm} show={show} onCloseCallback={onClose} title="Redeem Points" onSubmit={this._onSubmit} />
        );
    }
}
UserRedeemPoints.contextTypes = {
    config: PropTypes.object
};
export default UserRedeemPoints;