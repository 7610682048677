import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import AccountService from './../service/Account';
import jwt from 'jwt-decode';
import './LoginForm.css';

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.accountService = new AccountService();
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {email: '', password: '', error: null, isSending: false,};
        this._onChange = this._onChange.bind(this);
    }

    _onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    onSubmit(event) {

        event.preventDefault();
        const { email, password } = this.state;
        this.setState({ isSending: true });
        this.accountService.login(email, password).then(res=> {
                localStorage.clear();
                localStorage.setItem('token', res.token);
                let decode = jwt(res.token);
                localStorage.setItem('username', decode.so_user_name);
                this.props.history.push('/dashboard/users');
                if(this.props.loadConfig){
                    this.setState({ isSending: false, "errorMsg": '' });
                    this.props.loadConfig();
                }
                else{
                    this.setState({ isSending: false, "errorMsg": "Something went wrong. Contact support." });
                }
            },
            err=> {
                console.log(err);
                this.setState({ isSending: false, "errorMsg": "Invalid credentials. Please check again." });
            });
    }

    render() {
        return (<div className="account-sub-container">
                    <div className="card">
                    <div className="card-body">
                        <div className="text-center">
                            <img className="img-fluid" src={require('./../assets/images/shoutout_logo.png')} alt="logo" />
                        </div>
                        <div className="text-center">
                            <h1 className="">Login</h1>
                        </div>
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <label for="email" className="">Email address</label>
                                <input className="login" aria-describedby="emailHelp" type="email" name="email" placeholder="username@domain.com" required autocomplete="off" ref="email" onChange={this._onChange}/>
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div className="form-group">
                                <label for="password">Password</label>
                                <input className="login" type="password" name="password" placeholder="password" required  ref="password" onChange={this._onChange}/>
                            </div>
                            {/* <div className="form-check">
                                <input type="checkbox" className="form-check-input" name="check" />
                                <label className="form-check-label" for="exampleCheck1">&nbsp;Check me out</label>
                            </div> */}
                            <div className="form-group">
                                {this.state.error ? (<small className="text-center text-danger"> {this.state.error} </small>) : null}
                            </div>
                            <div className="text-right">
                                <Button type="submit" bsStyle="primary" bsSize="large" disabled={this.state.isSending}>Login</Button>
                            </div>
                        </form>
                        <br/>
				        {/* <div className="text-center mt-3">
					            <Link to="/signup"><small id="emailHelp" className="form-text text-muted">Not a member yet, Signup today.</small></Link>
				        </div> */}
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginForm;