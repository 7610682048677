import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class SignupForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: null
		};
		this.onSubmit = this.onSubmit.bind(this);
	}

	isValid() {
		/*const { errors, isValid } = validateInput(this.state);

		if (!isValid) {
			this.setState({ errors });
		}

		return isValid;*/
	}

	onSubmit(event) {
		//alert('Textarea value is: ' + this.state.identifier + ' & ' + this.state.password);
		event.preventDefault();
		/*if (this.isValid()) {
			this.setState({ errors: {}, isLoading: true });
			this.props.login(this.state).then(
				(res) => this.context.router.push("/home"),
				(err) => this.setState({ errors: err.data.errors, isLoading: false})
			);
		}*/
	}


	render() {

		return (
			<div className="account-sub-container">
			<div className="card">
			<div className="card-body">
				<div className="text-center">
					<img className="img-fluid" src={require('./../assets/images/shoutout_logo.png')} alt="logo" />
				</div>
				<div className="text-center">
					<h1 className="">Sign up</h1>
				</div>
				<form onSubmit={this.onSubmit}>
					<div className="form-group">
						<label for="email" className="">Email address</label>
						<input className="login" aria-describedby="emailHelp" type="email" name="email" placeholder="username@domain.com" required autocomplete="off" ref="email" />
						<small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
					</div>
					<div className="form-group">
						<label for="password">Password</label>
						<input className="login" type="password" name="password" placeholder="password" required  ref="password" />
					</div>
					{/* <div className="form-check">
						<input type="checkbox" className="form-check-input" name="check" />
						<label className="form-check-label" for="exampleCheck1">&nbsp;Check me out</label>
					</div> */}
					<div className="form-group">
						{this.state.error ? (<small className="text-center text-danger"> {this.state.error} </small>) : null}
					</div>
					<div className="text-center">
						<Button type="submit" bsStyle="primary" bsSize="large" >Sign up</Button>
					</div>
				</form>
				<br/>
				<div className="text-center mt-3">
					<Link to="/login"><small id="emailHelp" className="form-text text-muted">Already a member, Login here.</small></Link>
				</div>
				
			</div>
		</div>
	</div>
		);
	}
}

export default SignupForm;