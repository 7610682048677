/**
 * Created by madura on 12/5/16.
 */
import React, {Component} from 'react';
import Rewards from './Rewards';
import RewardLogin from './RewardLogin';
import {Grid, Row, Col} from 'react-bootstrap';
import Shoutout from './../../services/Shoutout';
class RewardsPage extends Component {

    constructor(props) {
        super(props);
        this.onLogIn = this.onLogIn.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.shoutout = new Shoutout();
        this.state = {customer: null};
    }

    onLogIn(filter) {
        //TODO:move login call to RewardLogin
        this.shoutout.loadCustomer(filter).then(res => {
            let item = res.Items[0];

            this.setState({
                customer: {
                    "id": item.id,
                    "loyaltyId": item.loyalty_id,
                    "name": item.name,
                    "points": item['total_points'] ? item['total_points'] : 0
                }
            });
        });
    }


    onLogout() {
        this.setState({customer: null});
    }

    render() {
        return (
            <div>
                <Grid>
                    <Row>
                        <Col lg={6} sm={8} xs={12} lgOffset={3} smOffset={2}>
                            <RewardLogin customer={this.state.customer} onLogIn={this.onLogIn}
                                         onLogout={this.onLogout}/>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <h3 className="text-center">Rewards</h3>
                        <Rewards customer={this.state.customer}/>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default RewardsPage;