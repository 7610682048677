/**
 * Created by madura on 11/29/16.
 */
import React, {Component} from 'react';
import {Row, Col, Panel,ControlLabel,Form,FormGroup,Button,FormControl} from 'react-bootstrap';
import Shoutout from './../../services/Shoutout';
import ReactDOM from 'react-dom';
class CollectPage extends Component {
    constructor(){
        super();
        this.shoutout=new Shoutout();
        this.onSubmit=this.onSubmit.bind(this);
    }

    onSubmit(event){
        event.preventDefault();
        let billValue = ReactDOM.findDOMNode(this.refs['billValue']).value;
        let loyaltyId = ReactDOM.findDOMNode(this.refs['loyaltyId']).value;
        let notes = ReactDOM.findDOMNode(this.refs['note']).value;
        if(billValue>0){
            this.shoutout.collectPoints(billValue,loyaltyId,notes).then(res=>{
                ReactDOM.findDOMNode(this.refs['billValue']).value=0;
                ReactDOM.findDOMNode(this.refs['loyaltyId']).value="";
                ReactDOM.findDOMNode(this.refs['note']).value="";
            });
        }
    }

    render() {
        return (
            <Row>
                <Col lg={6} sm={8} xs={12} lgOffset={3} smOffset={2}>
                    <Panel header={<p className="text-center">Collect Points</p>} bsStyle="primary">
                        <Form horizontal onSubmit={this.onSubmit}>
                            <FormGroup controlId="loyaltyId">
                                <Col componentClass={ControlLabel} sm={3}>
                                    Loyalty Id
                                </Col>
                                <Col sm={6}>
                                    <FormControl type="text" placeholder="xxx-123" ref="loyaltyId" required/>
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="code">
                                <Col componentClass={ControlLabel} sm={3}>
                                    Enter Bill Value
                                </Col>
                                <Col sm={6}>
                                    <FormControl type="number" placeholder="2000" ref="billValue" required/>
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="code">
                                <Col componentClass={ControlLabel} sm={3}>
                                    Note
                                </Col>
                                <Col sm={6}>
                                    <FormControl type="text" placeholder="Haircut" ref="note"/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col smOffset={4} sm={4}>
                                    <Button type="submit" bsStyle="info" bsSize="large" block>
                                        Submit
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </Panel>
                </Col>
            </Row>
        );
    }
}

export default CollectPage;