const handleErrors = (response) => {
    if (!response.ok) {
        // throw Error(response.statusText);
        return Promise.reject();
    }
    return response;
};

const parseJson = (response) => {
    try {
        return response.json();
    } catch (e) {
        console.error("Cannot parse:", response);
        return Promise.reject();
    }
};

const fetchGet = (url,isProtected=true, showLoader=true,track) => {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        isProtected: isProtected,
        showLoader: showLoader,
        track
    }).then(handleErrors).then(parseJson)
};

const fetchPost = (url, body, track) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        isProtected: true,
        showLoader: true,
        body: JSON.stringify(body),
        track
    }).then(handleErrors).then(parseJson)
};
export { handleErrors, parseJson, fetchGet, fetchPost };