import React from 'react';
import { Modal, Form, FormControl, ControlLabel, HelpBlock, FormGroup, Button } from 'react-bootstrap';
import ShoutOUTLoyaltyService from './../../services/ShoutoutLoyaltyService';
import { notify } from 'react-notify-toast';

class UserRedeemValidation extends React.Component {
    constructor() {
        super();
        this.state = { otpCode: "", isSending: false };
        this.onChangeCode = this.onChangeCode.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    onChangeCode(e) {
        this.setState({ otpCode: e.target.value });
    }

    async onConfirm(e) {
        try {
            e.preventDefault();
            this.setState({ isSending: true });
            const redeemPointResponse=await ShoutOUTLoyaltyService.redeemPointConfirm(this.props.loyaltyId,this.props.otpToken, this.state.otpCode,this.props.formData);
            notify.show("Point redeemed successfully", "success");

            this.setState({ isSending: false, otpCode: "" });
            this.props.onClose(redeemPointResponse);
        } catch (e) {
            notify.show("Point redeemed failed. Please check the code.", "error");
            this.setState({ isSending: false });
        }
    }



    render() {
        const { show, onClose } = this.props;
        const { otpCode, isSending } = this.state;

        return (
            <Modal show={show} onHide={onClose}>
                <Form onSubmit={this.onConfirm}>
                    <Modal.Header closeButton>
                        <Modal.Title>Redeem Point Confirm</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>


                        <FormGroup controlId="coupon-code" >
                            <ControlLabel>Enter code:</ControlLabel>
                            <FormControl type="text" placeholder="ZBC123" name="otpCode" required onChange={this.onChangeCode} value={otpCode} />
                            <HelpBlock>Ask from customer to provide the OTP code which is received to the customer's mobile number registered with the system.</HelpBlock>
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" onClick={onClose} disabled={isSending} bsStyle="danger">Cancel</Button>

                        <Button type="submit" disabled={isSending||!otpCode} bsStyle="primary">{isSending ? "Confirming..." : "Confirm"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }
}
export default UserRedeemValidation;