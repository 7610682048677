/**
 * Created by asankanissanka on 6/27/17.
 */
/**
 * Created by madura on 11/29/16.
 */
import React, { PureComponent } from 'react';
import { Panel, Button } from 'react-bootstrap';
import { Column, Table, AutoSizer, SortDirection, InfiniteLoader } from 'react-virtualized';
import { sortBy as _sortBy, reverse } from 'lodash';
import Pagination from './Pagination';
import './UsersList.css';

class UsersList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sortDirection: SortDirection.ASC,
            sortBy: 'loyalty_id'
        };
        this._sort = this._sort.bind(this);
        this._selectUser = this._selectUser.bind(this);
    }

    _sort({ sortBy, sortDirection }) {

        this.setState({ sortBy, sortDirection })
    }

    _rowClassName({ index }) {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    }

    _selectUser({ rowData }) {
        this.props.showProfile(rowData);
    }
    render() {
        const { sortBy, sortDirection } = this.state;
        const { users, filters, loadUsers, isLoadingUsers,hasMoreResults } = this.props;
        // let sortedList = _sortBy(users, [sortBy]);
        let sortedList = users;
        if (sortDirection === SortDirection.DESC) {
            reverse(sortedList);
        }
        return (
            <Panel bsStyle="primary" className="user-list">
                <div>
                    <InfiniteLoader
                        isRowLoaded={(index) => {
                            return !!sortedList[index];
                        }}

                        loadMoreRows={() => {
                            if (!isLoadingUsers && hasMoreResults) {
                                loadUsers(filters);
                            }
                        }}
                        rowCount={sortedList.length || 0}
                    >
                        {({ onRowsRendered, registerChild }) => (
                            <AutoSizer>
                                {({ width }) => {

                                    return <Table
                                        ref='UserTable'
                                        disableHeader={false}
                                        headerHeight={60}
                                        height={650}
                                        rowHeight={50}
                                        rowGetter={({ index }) => sortedList[index]}
                                        rowCount={sortedList.length || 0}
                                        // sortBy={sortBy}
                                        // sortDirection={sortDirection}
                                        width={width}
                                        sort={this._sort}
                                        rowClassName={this._rowClassName}
                                        onRowClick={this._selectUser}
                                        ref={registerChild}
                                        onRowsRendered={onRowsRendered}
                                    >
                                        <Column
                                            label="#"
                                            dataKey='index'
                                            width={20}
                                            cellRenderer={({ rowIndex }) => rowIndex + 1}
                                            disableSort={true}
                                            flexGrow={1}
                                        />

                                        <Column
                                            label="Name"
                                            dataKey='name'
                                            width={70}
                                            flexGrow={1}
                                        />

                                        <Column
                                            label="Loyalty ID"
                                            dataKey='loyalty_id'
                                            width={70}
                                            flexGrow={1}

                                        />

                                        <Column
                                            label="Points"
                                            dataKey='points'
                                            width={30}
                                            flexGrow={1}
                                        />
                                        <Column
                                            label=" "
                                            dataKey=''
                                            width={60}
                                            cellRenderer={({ rowData }) => {
                                                return <Button bsStyle="primary">View</Button>
                                            }}

                                        />
                                    </Table>
                                }}
                            </AutoSizer>
                        )}
                    </InfiniteLoader>
                </div>
            </Panel>
        );
    }
}

export default UsersList;