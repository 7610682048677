/**
 * Created by Madura on 01/11/2016.
 */
import React from "react";

class ReadableDateSelectorField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {[this.props.name]: ""};
        this.handleChange = this.onChange.bind(this);
    }

    componentWillMount() {
        this.props.schema.enum = [];
        let enumOptions = [];
        this.props.schema.enumNames.forEach((value)=> {
            let dateVal = this.generateDate(value);
            this.props.schema.enum.push(dateVal);
            enumOptions.push({label: value, value: dateVal});
        });
        this.setState({
            [this.props.name]: this.props.schema.enum[0], enumOptions: enumOptions
        }, () => this.props.onChange(this.state[this.props.name]));
    }

    generateDate(value) {
        let currentDate = new Date();
        let split = value.split(' ');
        switch (split[1]) {
            case 'Month':
                currentDate.setMonth(currentDate.getMonth() + parseInt(split[0]));
                break;
            default:
                break;
        }
        return currentDate.toISOString().split('T')[0];
    }

    onChange(event) {
        this.setState({
            [this.props.name]: event.target.value
        }, () => this.props.onChange(this.state[this.props.name]));
    }

    render() {
         return ( <div>
                <label htmlFor="__datesel"
                       className="control-label ">{this.props.schema.title}{this.props.required ? " *" : ""}</label>
                <select id="__datesel"
                        className="form-control"
                        required={this.props.required}
                        value={this.state[this.props.name]}
                        onChange={this.handleChange}>{
                    this.state.enumOptions.map(({value, label}, i) => {
                        return <option key={i} value={value}>{label}</option>;
                    })
                }</select>
            </div>
        );
    }
}

export default ReadableDateSelectorField;