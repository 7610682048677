import React from "react";
import {
    Button,
    Modal
} from "react-bootstrap";

class ConfirmBox extends React.PureComponent {
    render() {
        const { show, onHide, confirmCallback, title, message, cancel, confirm} = this.props;
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{message}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide}>{cancel}</Button>
                    <Button bsStyle="warning" onClick={confirmCallback} >{confirm}</Button>
                </Modal.Footer>

            </Modal>
        );
    }
};

export default ConfirmBox;