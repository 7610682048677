import { fetchGet, fetchPost } from './CommonServiceUtils';
import { notify } from 'react-notify-toast';

const BASE_URL = "https://api.getshoutout.com/loyaltyservice2/";
const coreService = "https://api.getshoutout.com/coreservice/";



class Shoutout {
    constructor() {
        this.token = localStorage.getItem("token");
    }

    static getRewards() {
        return fetchGet(BASE_URL + "rewards");
    }

    static getReward(rewardId) {
        return fetchGet(BASE_URL + "rewards/" + rewardId);
    }

    static getConfig() {
        return fetchGet(BASE_URL + "configs", true, false);
    }

    static registerUser(registrationDetails) {
        return fetchPost(BASE_URL + "users", registrationDetails);
    }

    static getUser(loyaltyId) {
        return fetchGet(BASE_URL + "users/" + loyaltyId);
    }
    static async redeemRewardRequest(userId, rewardId, selectedBranch) {

        return fetchPost(BASE_URL + "reward/redeem",
            {
                rewardId: rewardId,
                userId: userId,
                rewardMetadata: {
                    claimLocation: selectedBranch
                }
            }
        );
        // return new Promise((resolve) => {
        //     setTimeout(() => {
        //         resolve({ otpToken: "123" });
        //     }, 2000)
        // })
        // let url = BASE_URL + "rewards";
        // let requestBody = { itemId: itemId, contactId: contactId };
        // return new Promise((resolve, reject) => {
        //     fetch(url, {
        //         method: 'POST',
        //         headers: {
        //             'Accept': 'application/json, text/plain, */*',
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Bearer ' + (token ? token : this.token),
        //         },
        //         body: JSON.stringify(requestBody)
        //     }).then(function (response) {
        //         if (!response.ok) {
        //             throw Error(response.statusText);
        //         }
        //         return response.json();
        //     }).then(function (json) {
        //         console.log('parsed json', json);
        //         notify.show("Coupon sent", 'success');
        //         resolve(json);
        //     }).catch(function (ex) {
        //         notify.show("Couldn't send coupon", 'error');
        //         console.log('parsing failed', ex);
        //         reject(ex);
        //     });
        // });
    }

    static async redeemRewardConfirm(userId, rewardId, otpToken, otpCode, mobileNumber, selectedBranch) {

        return fetchPost(BASE_URL + "reward/redeem",
            {
                rewardId: rewardId,
                userId: userId,
                otpValidation: {
                    otpToken: otpToken,
                    otpCode: otpCode
                },
                rewardMetadata: {
                    claimLocation: selectedBranch
                }
            }
        );


        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         if (otpCode !== "123") {
        //             reject("Invalid code");
        //         } else if (!otpToken) {
        //             reject("Invalid code");
        //         } else {
        //             resolve("Done");
        //         }

        //     }, 2000)
        // })
    }

    static searchUser(loyaltyId) {
        return fetchGet(BASE_URL + "users/" + loyaltyId);
    }

    static addOrRedeemPoints(requestBody, action) {
        return fetchPost(BASE_URL + "points?action=" + action, requestBody);
    }
    static loadCustomer(attributes) {
        let url = coreService + "contacts/filter?last_eval_key_id=undefined&limit=1";
        let filters = [];
        for (let key of Object.keys(attributes)) {
            filters.push({
                "_selectedFilter": 1,
                "columnName": key,
                "searchKey": attributes[key]
            });
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.token
                },
                body: JSON.stringify(filters)
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                console.log('parsed json', json);
                if (json.Count && json.Count === 1) {
                    notify.show('Logged in', 'success');
                    resolve(json);
                } else {
                    notify.show('Not found', 'error');
                    reject({ "error": "Not found" });
                }

            }).catch(function (ex) {
                console.log('parsing failed', ex);
                notify.show('Not found', 'error');
                reject(ex);
            });
        });
    }

    static loadCustomers(attributes) {
        let url = coreService + "contacts/filter?last_eval_key_id=undefined";
        let filters = [];
        for (let key of Object.keys(attributes)) {
            filters.push({
                "_selectedFilter": 1,
                "columnName": key,
                "searchKey": attributes[key]
            });
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.token
                },
                body: JSON.stringify(filters)
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                console.log('parsed json', json);
                if (json.Count && json.Count === 1) {
                    notify.show('Logged in', 'success');
                    resolve(json);
                } else {
                    notify.show('Not found', 'error');
                    reject({ "error": "Not found" });
                }

            }).catch(function (ex) {
                console.log('parsing failed', ex);
                notify.show('Not found', 'error');
                reject(ex);
            });
        });
    }

    // Not used.
    // submitCoupon(couponCode) {
    //     let url = rewardApiUrl + "coupons";
    //     let requestBody = {
    //         code: couponCode
    //     };
    //     return new Promise((resolve, reject) => {
    //         fetch(url, {
    //             method: 'PUT',
    //             headers: {
    //                 'Accept': 'application/json, text/plain, */*',
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + this.token
    //             },
    //             body: JSON.stringify(requestBody)
    //         }).then(function (response) {
    //             return response.json();
    //         }).then(function (json) {
    //             console.log(json);
    //             notify.show('Coupon redeemed', 'success');
    //             resolve(json);

    //         }).catch(function (ex) {
    //             console.log('parsing failed', ex);
    //             notify.show('Coupon not valid', 'error');
    //             reject(ex);
    //         });
    //     });
    // }


    collectPoints(billValue, loyaltyId, notes) {
        let url = BASE_URL + "points?action=collect";
        let requestBody = {
            userId: loyaltyId,
            activityData: { bill: billValue, note: notes }
        };
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.token
                },
                body: JSON.stringify(requestBody)
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                console.log('parsed json', json);
                notify.show("Points added", 'success');
                resolve(json);
            }).catch(function (ex) {
                console.log('parsing failed', ex);
                notify.show("Couldn't add points", 'error');
                reject(ex);
            });
        });
    }

    // not used.
    // requestJoin(requestBody) {
    //     let url = webhookApiUrl + "webhook/contact?api_key=521f14c0-b6c5-11e6-9fbb-816cb2abab5b&form_id=ScrReqJoin&source=shoutout_forms&form_name=Request Join";
    //     return new Promise((resolve, reject) => {
    //         fetch(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json, text/plain, */*',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(requestBody)
    //         }).then(function (response) {
    //             return response.json();
    //         }).then(function (json) {
    //             console.log('parsed json', json);
    //             notify.show("Request sent", 'success');
    //             resolve(json);
    //         }).catch(function (ex) {
    //             console.log('parsing failed', ex);
    //             notify.show("Couldn't send request", 'error');
    //             reject(ex);
    //         });
    //     });
    // }

    // Not used.
    // getPoints(contactId, token) {
    //     let url = rewardApiUrl + "points/" + contactId;
    //     return new Promise((resolve, reject) => {
    //         fetch(url, {
    //             method: 'GET',
    //             headers: {
    //                 'Accept': 'application/json, text/plain, */*',
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token,
    //             }
    //         }).then(function (response) {
    //             return response.json();
    //         }).then(function (json) {
    //             console.log('parsed json', json);
    //             resolve(json);
    //         }).catch(function (ex) {
    //             console.log('parsing failed', ex);
    //             notify.show("Couldn't load your points", 'error');
    //             reject(ex);
    //         });
    //     });
    // }

    // Not used.
    // getRewardById(rewardId) {
    //     let url = rewardApiUrl + "rewards/" + rewardId;
    //     return new Promise((resolve, reject) => {
    //         fetch(url, {
    //             method: 'GET',
    //             headers: {
    //                 'Accept': 'application/json, text/plain, */*',
    //                 'Content-Type': 'application/json'
    //             }
    //         }).then(function (response) {
    //             return response.json();
    //         }).then(function (json) {
    //             console.log('parsed json', json);
    //             resolve(json);
    //         }).catch(function (ex) {
    //             notify.show("Couldn't load reward", 'error');
    //             console.log('parsing failed', ex)
    //         });

    //     });
    // }

    /* new */
    loadLoyaltyUsers({limit=100,skip=0,searchKey}) {
        return fetchGet(`${BASE_URL}users?limit=${limit}&skip=${skip}&${searchKey?'searchKey='+searchKey:''}`);
    }

    // Not used.
    // loadUsers(filters) {
    //     return fetchPost(shoutOUTApiUrl + "v8/contacts/filter", filters);
    // }

    // Not used.
    // createUsers(users) {
    //     let url = shoutOUTApiUrl + "v8/contacts";
    //     return fetch(url, {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json, text/plain, */*',
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + this.token
    //         },
    //         body: JSON.stringify(users)
    //     }).then(function (response) {
    //         return response.json();
    //     });
    // }

    // Not Used.
    // createActivity(activity) {
    //     let url = shoutOUTApiUrl + "items/activities/records";
    //     return fetch(url, {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json, text/plain, */*',
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + this.token,
    //         },
    //         body: JSON.stringify(activity)
    //     }).then(function (response) {
    //         if (!response.ok)
    //             throw Error('error creating activity');
    //         return response.json();
    //     });
    // }

    // Not used.
    // getCoupon(eventId, contactId) {
    //     let url = shoutOUTSeverlessUrl + 'v8/coupons/' + eventId + '/' + contactId;
    //     return fetch(url, {
    //         method: 'GET',
    //         headers: {
    //             'Accept': 'application/json, text/plain, */*',
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + this.token,
    //         }
    //     }).then(function (response) {
    //         if (!response.ok)
    //             throw Error('error creating activity');
    //         return response.json();
    //     });
    // }
}

export default Shoutout;