import React, { Component } from 'react';
import Notifications from 'react-notify-toast';
import Shoutout from './services/Shoutout';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import fetchIntercept from 'fetch-intercept';
import Loader from 'react-loader';
import jwt from 'jwt-decode';
import 'react-virtualized/styles.css';
// import AuthRoute from './AuthRoute.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './lib/bootstrap.min.css';
import './App.css';


import Home from './components/home/Home';
import { AccountPage } from './components/account/AccountPage';
const checkAuth = () => {
    const token = localStorage.getItem('token');
    return token !== null;
}


const AuthRoute = ({ component: Component, config, scopes, ...rest }) => (
    <Route {...rest} render={props => (
        checkAuth() ? (
            <Component {...props} config={config} scopes={scopes} />
        ) : (
                <Redirect to={{ pathname: '/login' }} />
            )
    )} />
);


const defaultScope = {
    "klippointscollect": {
        read: true,
        write: true
    },
    "klippointsredeem": {
        read: true,
        write: true
    },
    "klipusers": {
        read: true,
        write: true
    },
    "kliprewardsredeem": {
        read: true,
        write: true
    },
    "kliprewardsclaim": {
        read: true,
        write: true
    }
}
class App extends Component {
    constructor() {
        super();
        this.state = { config: {}, loaded: true, scopes: {...defaultScope} };
        this.loadConfig = this.loadConfig.bind(this);
        this.TOKEN = null;
    }
    componentDidMount() {
        this.loadConfig();

    }
    _getToken() {
        if (!this.TOKEN) {
            const token = localStorage.getItem("token");
            if (token === null || token === '') {
                if (this.props.history) {
                    this.props.history.push('/login');
                }
                this.TOKEN = null;

            } else {
                this.TOKEN = token;
                const decode = jwt(token);
                if (decode.scopes && Object.keys(decode.scopes).length > 0) {
                    const scopes = Object.entries(decode.scopes).reduce((result, [key, value]) => {

                        if (key.startsWith("klip")) {
                            result[key] = {};
                            value.actions.forEach(element => {
                                result[key][element] = true;
                            });
                        }
                        return result;
                    }, {...defaultScope});

                    this.setState({ scopes: scopes });
                }

            }
        }
        return this.TOKEN;
    }
    componentWillMount() {
        let ctx = this;

        fetchIntercept.register({
            request: function (url, config) {
                // Modify the url or config here
                if (config['isProtected']) {
                    config['headers']['Authorization'] = 'Bearer ' + ctx._getToken();
                    delete config.isProtected;
                };

                ctx.setState({ loaded: !config['showLoader'] });
                return [url, config];
            },

            requestError: function (error) {
                console.error("Request Error");
                // Called when an error occured during another 'request' interceptor call
                return Promise.reject(error);
            },
            response: function (response) {
                ctx.setState({ loaded: true });
                if (response.url.indexOf("/login") === -1 && (response.status === 401 || response.status === 403)) {
                    // ctx.props.history.push('/logout');
                    localStorage.removeItem('token');
                    //   window.location.reload();
                    ctx._getToken();
                }
                if (response.status === 400) {
                    return Promise.reject(response);
                }
                return response;
            },
            responseError: function (error) {
                console.error("response error");
                ctx.setState({ loaded: true });
                //this.props.history.push('/dashboard/logout');
                return Promise.reject(error);
            }
        });
    }

    getChildContext() {
        return { config: this.state.config };
    }
    loadConfig(config) {
        Shoutout.getConfig().then(res => {
            this.setState({ config: res });
        });
        this.TOKEN = null;
        this._getToken();
    }
    render() {

        return (

            <div id="app">

                <Notifications options={{ zIndex: 9000 }} />
                <Loader loaded={this.state.loaded} className="loader" />
                {
                    // React.cloneElement(this.props.children, {
                    //     loadConfig: this.loadConfig
                    // })
                }

                <AuthRoute path="/dashboard" component={Home} config={this.state.config} scopes={this.state.scopes}></AuthRoute>
                <Route exact path="/login" render={(props) =>
                    <AccountPage {...props} loadConfig={this.loadConfig} />
                }
                />
                <Route exact path="/logout" render={(props) =>
                    <AccountPage {...props} loadConfig={this.loadConfig} />
                }
                />
                <Route exact path="/signup" render={(props) =>
                    <AccountPage {...props} loadConfig={this.loadConfig} />
                }
                />
                <AuthRoute exact path="/" component={Home} config={this.state.config} scopes={this.state.scopes}></AuthRoute>
                {/* <Route render={AccountPage}></Route> */}





            </div>
        )
    }
}

App.childContextTypes = {
    config: PropTypes.object
};

export default App;