import React, {Component} from 'react';

class Logout extends Component {

    componentDidMount() {
        console.debug("Logging out....")
            localStorage.clear();
            this.props.history.push('/login');
    }

    render() {
        return (<div className="account-sub-container">
                    <h3><i>See you soon :(</i></h3>
            </div>
        );
    }
}

export default Logout;