/**
 * Created by madura on 11/22/16.
 */
import React, { Component } from 'react';
import ShoutoutLoyaltyService from '../../services/ShoutoutLoyaltyService';
import { Form, FormGroup, Button } from 'react-bootstrap';
import './Coupon.css';


import { notify } from 'react-notify-toast';
class CouponComponent extends Component {
    constructor() {
        super();
        this.state = { couponCode: '', isInvalid: false, isSending: false };
        this.redeemReward = this.redeemReward.bind(this);
        this._onChangeCode = this._onChangeCode.bind(this);
        this._clearCode = this._clearCode.bind(this);
    }

    redeemReward(event) {
        event.preventDefault();
        let couponCode = this.state.couponCode;
        this.setState({ isSending: true });
        ShoutoutLoyaltyService.claimReward(couponCode).then(res => {
            notify.show("Coupon redeemed successfully", "success");
            this.setState({ couponCode: '', isSending: false, isInvalid: false });
            this.onExit(res);
        }, err => {
            notify.show("Invalid coupon code", "error");
            this.props.onInvalidCoupon();
            this.setState({ isSending: false, isInvalid: true });
        });
    }

    onExit(result) {
        if (this.props.callback) {
            this.props.callback(result);
        }
    }
    _onChangeCode(e) {
        this.setState({ couponCode: e.target.value });
    }
    _clearCode() {
        this.setState({ couponCode: '' });
    }
    render() {
        return (
            <Form horizontal onSubmit={this.redeemReward}>
                <FormGroup controlId="code" validationState={this.state.isInvalid ? "error" : "success"}>
                    <div className="container">
                        <div className="row">
                        <div className="col-sm-2"></div>
                            <div className="col-sm-3">
                                <div className="coupon-enter-code-text">
                                        Enter Code
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <input className="reward-input" type="text" placeholder="ABC123" onChange={this._onChangeCode} value={this.state.couponCode} />
                            </div>
                            <div className="col-sm-3"></div>
                        </div>
                    </div>
                    
                   
                </FormGroup>

                <FormGroup>
                    <div className="container">
                        <div className="row">
                        <div className="col-sm-3"></div>
                            <div className="col-md-3">
                                <Button type="submit" bsStyle="primary" bsSize="large" block disabled={this.state.isSending}>
                                    {this.state.isSending ? "Sending..." : "Submit"}
                                </Button>
                            </div>
                            <div className="col-md-3">
                                <Button type="button" bsStyle="danger" bsSize="large" block disabled={this.state.isSending} onClick={this._clearCode}> Clear </Button>
                            </div>
                            <div className="col-sm-3"></div>
                        </div>
                    </div>
                </FormGroup>
            </Form>
        );
    }
}

export default CouponComponent;

