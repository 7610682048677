import React, { Component } from 'react';
import { Panel, Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import ShoutOUTService from './../../services/Shoutout';
import { isEmpty, chain, sortBy, reverse } from 'lodash';
import './LeaderBoard.css';

class LeaderBoard extends Component {
    constructor() {
        super();
        this.state = {
            leaderLists: []
        };

        this.panelColorClass = ["success", "warning", "info"];
        this.shoutout = new ShoutOUTService();
        this.loadingRequestSent = false;
    }


    _loadLeaderDetails(config) {
        let userIdPatternRegex = config.userIdPatterns.userType;
        if (userIdPatternRegex) {
            userIdPatternRegex = new RegExp(userIdPatternRegex);
            this.loadingRequestSent = true;
            this.shoutout.loadLoyaltyUsers().then(res => {

                let transformedResults = chain(res.Items).groupBy((item) => {
                    let match = userIdPatternRegex.exec(item.user_id);
                    return match[0];
                }).transform((result, item, key) => {

                    let sortedList = reverse(sortBy(item, 'points'));
                    result.push({
                        category: config.userIdPatterns.userTypeMap[key].title,
                        leaders: sortedList
                    });
                    return result;
                }, []).value();
                this.setState({ leaderLists: transformedResults });
            });
        }

    }

    componentDidMount() {
        if (!this.loadingRequestSent && !isEmpty(this.context.config)) {
            this._loadLeaderDetails(this.context.config);
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {

        if (!this.loadingRequestSent && isEmpty(this.context.config) && !isEmpty(nextContext.config)) {
            this._loadLeaderDetails(nextContext.config);
        }
    }
    render() {

        return (<div className="leader-board">
                    <div className="custom-margin-top"></div>
            <h3 className="text-center">Leader Board</h3>
            <br />
            <Row>

                {this.state.leaderLists.map((item, leaderListIndex) => {
                    return (
                        <Col sm={4} xs={12} key={item.category}>
                            <Panel bsStyle={this.panelColorClass[leaderListIndex]} >
                                <Panel.Heading>
                                    {item.category}
                                </Panel.Heading>
                                <div className="leader-content">
                                    <ListGroup>{
                                        item.leaders.map((leaderObj, index) => {
                                            return (
                                                <ListGroupItem key={leaderObj.user_id} bsStyle={index === 0 ? "danger" : null}>
                                                    <Row>
                                                        <Col xs={2} className={`lead-${index}`}>
                                                            {index + 1}
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Avatar name={(leaderObj.user_data.name || 'Unknown').charAt(0)} round={true} size={40} />
                                                        </Col>
                                                        <Col xs={7}>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    {leaderObj.user_data.name}
                                                                </Col>
                                                                <Col xs={12} className={index === 0 ? "text-white" : "text-success"}>
                                                                    {leaderObj.points}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                </ListGroupItem>
                                            )
                                        })

                                    }
                                    </ListGroup>
                                </div>
                            </Panel>
                        </Col>
                    )
                })}

            </Row>


        </div>);
    }
}
LeaderBoard.contextTypes = {
    config: PropTypes.object
};

export default LeaderBoard;