/**
 * Created by madura on 11/29/16.
 */
import React, { Component } from 'react';
import CouponComponent from './CouponComponent';
class CouponPage extends Component {
    constructor(props) {
        super(props);
        this.state = { reward: null, isLoadingReward: false };
        this.onSubmit = this.onSubmit.bind(this);
        this._invalidCoupon = this._invalidCoupon.bind(this);
    }

    onSubmit(res) {
        if (res.reward) {

            this.setState({ reward: res.reward });
        } else {
            this.setState({ reward: { name: res.coupon_type } });
        }
    }

    _invalidCoupon() {
        this.setState({ reward: null });
    }
    render() {
        return (
            <div className='container'>
                <div className="custom-margin-top"></div>



                <CouponComponent callback={this.onSubmit} onInvalidCoupon={this._invalidCoupon} />


                <div className="reward text-center">

                    {this.state.reward ? <div>
                        <h4 className="text-center"> {this.state.reward.name}</h4>
                        {this.state.reward.imageUrls&&<img src={this.state.reward.imageUrls[0]} width="150"/>}
                    </div> :

                        <h1> <br /> Submit a valid coupon  <br />  code to view the reward </h1>
                    }
                </div>


            </div>
        );
    }
}

export default CouponPage;