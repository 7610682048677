import React, { PureComponent } from 'react';
import Shoutout from './../../services/Shoutout';
import { notify } from 'react-notify-toast';
import FormView from './../forms/FormView';
import PropTypes from 'prop-types';
const defaultForm = {
    "form": {
        "jsonschema": {
            "type": "object",
            "title": "",

            "properties": {
                "nic": {
                    "type": "string",
                    "title": "NIC"
                },
                "points": {
                    "type": "number",
                    "title": "Points"
                },
                "note": {
                    "type": "string",
                    "title": "Notes"
                }
            },
            "required": [
                "nic",
                "points"
            ]
        },
        "uischema": {
            "ui:order": [
                "nic",
                "points",
                "note"
            ],
            "nic": {
                "ui:placeholder": "900203200V"
            },
            "points": {
                "ui:placeholder": "10"
            },
            "note": {
                "ui:placeholder": "Bill No: 26362"
            }
        }
    },
    "metadata": {
        "validation": {
            "attrName": "nic"
        }
    }
};
class UserAddPoints extends PureComponent {
    constructor() {
        super();

        this._onSubmit = this._onSubmit.bind(this);
    }


    _onSubmit(result, callback) {
        let formDataObj = result.formData;
        const config = this.context.config || {};
        const form = config.pointCollectForm||defaultForm;
        const validationAttr=form.metadata&&form.metadata.validation&&form.metadata.validation.attrName?form.metadata.validation.attrName:'';
        Shoutout.addOrRedeemPoints({ userId: this.props.loyaltyId.toString(), activityData: formDataObj, validation: { attrName: validationAttr, attrVal: formDataObj[validationAttr] } }, "collect").then(res => {

            notify.show("Points added successfully", "success");
            callback(null, res);
            this.props.onClose({ points: res.points ,tier_points:res.tier_points});
        }, err => {
            notify.show("Could not add points. Please check provided data", "error");
            callback("Could not add points. Please check provided data");
        });
      
    }

    render() {
        const { show, onClose } = this.props;
        const config = this.context.config || {};
        const form = config.pointCollectForm;
        return (
            <FormView form={form || defaultForm} show={show} onCloseCallback={onClose} title="Add Points" onSubmit={this._onSubmit} />
        );
    }
}

UserAddPoints.contextTypes = {
    config: PropTypes.object
};

export default UserAddPoints;