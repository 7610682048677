import React, {Component} from 'react';
import Shoutout from './../../services/Shoutout';
import './Rewards.css';
import CouponComponent from './../coupon/CouponComponent';
import {Col, Button, Image, Modal, Row} from 'react-bootstrap';
class Rewards extends Component {

    constructor() {
        super();
        this.shoutout = new Shoutout();
        this.state = {
            rewardList: {Items: []},
            showConfirmDialog: false,
            showRewardRedeemVerification: false
        };
        this.redeemReward = this.redeemReward.bind(this);
        this.redeemConfirm = this.redeemConfirm.bind(this);
    }

    componentWillMount() {
        this.couponVerifyModalClose();
    }

    componentDidMount() {
        Shoutout.getRewards().then((result)=> {
            this.setState({rewardList: result});
        });
    }


    redeemReward() {
        let ctx = this;
        let reward = this.state.selectedReward;
        this.setState({showConfirmDialog: false, selectedReward: null});
        this.shoutout.redeemReward(this.props.customer.id, reward.item_id, this.props.customer.token).then((result)=> {
            console.log(result);
            if (this.props.location && this.props.location === 'portal') {
                ctx.rewardRedeemVerificationModalOpen();
            } else {
                ctx.couponVerifyModalOpen();
            }
        }, (error)=> {

        });
    }

    redeemConfirm(reward) {
        this.setState({showConfirmDialog: true, selectedReward: reward});
    }

    redeemCancel() {
        this.setState({showConfirmDialog: false, selectedReward: null});
    }

    couponVerifyModalClose() {
        this.setState({showCouponComponent: false});
    }

    couponVerifyModalOpen() {
        this.setState({showCouponComponent: true});
    }

    rewardRedeemVerificationModalClose() {
        this.setState({showRewardRedeemVerification: false});
    }

    rewardRedeemVerificationModalOpen() {
        this.setState({showRewardRedeemVerification: true});
    }

    render() {
        return (
            <Row className="row-centered">
                {this.state.rewardList.Items.map(function (reward, i) {
                        return (<Col lg={3} md={4} sm={6} xs={12} key={reward.rewardId} className="col-centered">
                            <div className="reward text-center">
                                <Image src={ reward.imageUrls[0]}
                                       alt={reward.rewardName} height="200" className="reward-image"/>
                                <h4 className="text-center"> {reward.title}</h4>
                                <p className="text-center"> {reward.points} points</p>
                                <div className="text-center redeem-section">
                                    <Button bsStyle={this.props.customer && this.props.customer.points >= reward.points ? "info":"primary"} bsSize="large"
                                        className="redeem-button"
                                        onClick={this.redeemConfirm.bind(this, reward)}
                                        disabled={!this.props.customer || this.props.customer.points < reward.points}>
                                        <span
                                            className={this.props.customer && this.props.customer.points >= reward.points ? "glyphicon glyphicon-unlock" : "glyphicon glyphicon-lock"}
                                            aria-hidden="true"></span>&nbsp;&nbsp;Redeem
                                    </Button>
                                </div>
                            </div>

                        </Col>)
                    }.bind(this)
                )}
                <Modal show={this.state.showConfirmDialog} onHide={this.redeemCancel.bind(this)}
                       aria-labelledby="coupon-modal-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="coupon-modal-title">Confirm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Do you really want to redeem this?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="danger" bsSize="large" onClick={this.redeemCancel.bind(this)}>Cancel</Button>
                        <Button bsStyle="primary" bsSize="large" onClick={this.redeemReward.bind(this)}>Redeem</Button>
                    </Modal.Footer>

                </Modal>
                <Modal show={this.state.showCouponComponent} onHide={this.couponVerifyModalClose.bind(this)}
                       aria-labelledby="coupon-modal-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="coupon-modal-title">Redeem</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CouponComponent callback={this.couponVerifyModalClose.bind(this)}/>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showRewardRedeemVerification}
                       onHide={this.rewardRedeemVerificationModalClose.bind(this)}
                       aria-labelledby="coupon-modal-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="coupon-modal-title">Reward Redeemed</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>We have sent a coupon code to you via SMS, Visit the store and show the code to collect your
                            reward</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="success" bsSize="large" onClick={this.rewardRedeemVerificationModalClose.bind(this)}>Okay</Button>
                    </Modal.Footer>

                </Modal>
            </Row>
        );
    }
}


export default Rewards;