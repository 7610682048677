/**
 * Created by madura on 11/29/16.
 */
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import "./UsersPage.css";
import Shoutout from "./../../services/Shoutout";
import CreateUser from "./../create_user/CreateUser";
import UsersList from "./UsersList";
import UserProfile from "./UserProfile";
import FontAwesome from "react-fontawesome";
import PropTypes from "prop-types";
import Constants from "./../../Constants";

const limit = 100;
class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.shoutout = new Shoutout();
    this.state = {
      showCreateUserModal: false,
      formId: "Test",
      users: [],
      searchKey: "",
      showProfile: false,
      selectedUser: {},
      rewards: [],
      isLoadingUsers: false,
      hasMoreResults: true,
    };
    this.filters = [
      {
        columnName: "loyalty_id",
        _selectedFilter: 4,
      },
    ];
    this.closeCreateUserModal = this.closeCreateUserModal.bind(this);
    this.openCreateUserUserModal = this.openCreateUserUserModal.bind(this);
    this._clearSearch = this._clearSearch.bind(this);
    this._onChangeSearchKey = this._onChangeSearchKey.bind(this);
    this._loadUsers = this._loadUsers.bind(this);
    this._showProfile = this._showProfile.bind(this);
    this._hideProfile = this._hideProfile.bind(this);
    this._updateUser = this._updateUser.bind(this);
  }

  _loadUsers(filters) {
    this.setState({ isLoadingUsers: true });
    this.shoutout
      .loadLoyaltyUsers({
        limit,
        skip: this.state.users.length,
        searchKey: this.state.searchKey,
      })
      .then(
        (res) => {
          this.setState({
            users: [...this.state.users, ...res],
            hasMoreResults: res.length >= limit,
            isLoadingUsers: false,
          });
        },
        (err) => {
          this.setState({
            isLoadingUsers: false,
          });
        }
      );
  }

  loadRewards = () => {
    try {
      Shoutout.getRewards().then((res) => {
        const publishedRewards = res.filter((item) => item.status === 1);
        this.setState({ rewards: publishedRewards, isLoadingUsers: true });
      });
    } catch {
      this.setState({ isLoadingUsers: false });
    }
  };

  componentDidMount() {
    this._loadUsers(this.filters);
    this.loadRewards();
  }

  _clearSearch() {
    this.setState({ searchKey: "" }, () => {
      this._loadUsers(this.filters);
    });
  }

  _onChangeSearchKey(e) {
    this.setState({ searchKey: e.target.value });
  }

  _searchUser = () => {
    this.setState({ users: [] }, () => {
      this._loadUsers();
    });
  };

  _onKeyPressSearch = (e) => {
    if (e.keyCode === 13) {
      this._searchUser();
    }
  }

  closeCreateUserModal() {
    this.setState({ showCreateUserModal: false });
    this._loadUsers(this.filters);
  }

  openCreateUserUserModal() {
    this.setState({ showCreateUserModal: true });
  }

  _showProfile(user) {
    this.setState({ selectedUser: user, showProfile: true });
  }

  _hideProfile() {
    this.setState({ selectedUser: {}, showProfile: false });
  }

  _updateUser(newUserObj) {
    const users = [...this.state.users];
    let match = users.findIndex((item) => {
      return item.user_id === newUserObj.user_id;
    });
    if (match > -1) {
      const userMatched = users[match];
      userMatched.points = newUserObj.points;
      userMatched.tier_points = newUserObj.tier_points;
      this.setState({ users, selectedUser: userMatched });
    }
  }

  render() {
    const { userCreate = true } = this.context.config.klipConfig || {};
    const { scopes } = this.props;

    const { searchKey } = this.state;
    const searchResults = this.state.users
    //   ? this.state.users.filter((u) => {
    //       return (
    //         u.loyalty_id.toString().includes(searchKey) ||
    //         (u.name && u.name.toLowerCase().includes(searchKey.toLowerCase()))
    //       );
    //     })
    //   : this.state.users;

    return (
      <div className="user-page">
        <div className="container-fluid">
          {this.state.showProfile ? (
            <Row>
              <Col xs={12}>
                <UserProfile
                  user={this.state.selectedUser}
                  rewards={this.state.rewards}
                  onClickBack={this._hideProfile}
                  updateUser={this._updateUser}
                  selectedBranch={this.props.selectedBranch}
                  loadRewards={this.loadRewards}
                  scopes={scopes}
                />
              </Col>
            </Row>
          ) : (
            <div>
              <Row>
                <Col xs={12} sm={9}>
                  <FormGroup>
                    <InputGroup className="">
                      <FormControl
                        type="text"
                        placeholder="Search by loyalty Id or name or mobile number"
                        className="searchInput"
                        onChange={this._onChangeSearchKey}
                        onKeyUp={this._onKeyPressSearch}
                        value={searchKey}
                      />
                      <InputGroup.Button>
                        <Button
                          bsStyle="link"
                          className="search-icon-button"
                          onClick={this._searchUser}
                        >
                          <FontAwesome className="text-primary" name="search" />
                        </Button>
                        {searchKey && (
                          <Button
                            bsStyle="link"
                            className="search-icon-button"
                            onClick={this._clearSearch}
                          >
                            <FontAwesome className="text-danger" name="times" />
                          </Button>
                        )}
                      </InputGroup.Button>
                    </InputGroup>
                  </FormGroup>
                </Col>

                <Col xs={12} sm={3}>
                  {scopes[
                    Constants.USER_ACCESS_CONTROLS.MODULES.KLIP_REWARD_CLAIM
                      .moduleId
                  ].write &&
                    userCreate && (
                      <Button
                        bsStyle="primary"
                        bsSize="large"
                        block
                        onClick={this.openCreateUserUserModal}
                      >
                        Register User
                      </Button>
                    )}
                </Col>
              </Row>

              <UsersList
                filters={this.filters}
                users={searchResults}
                showProfile={this._showProfile}
                loadUsers={this._loadUsers}
                hasMoreResults={this.state.hasMoreResults}
                isLoadingUsers={this.state.isLoadingUsers}
              />
            </div>
          )}
        </div>
        {scopes[
          Constants.USER_ACCESS_CONTROLS.MODULES.KLIP_REWARD_CLAIM.moduleId
        ].write &&
          userCreate && (
            <CreateUser
              showModal={this.state.showCreateUserModal}
              onCloseCallback={this.closeCreateUserModal}
              formId={this.state.formId}
            />
          )}
      </div>
    );
  }
}

UsersPage.contextTypes = {
  config: PropTypes.object,
};
export default UsersPage;
