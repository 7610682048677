/**
 * Created by Madura on 31/10/2016.
 */
import React from "react";
import util from './../../node_modules/intl-tel-input/build/js/utils';
import $ from 'jquery';
import './../../node_modules/intl-tel-input/build/js/intlTelInput.min.js';
import './../../node_modules/intl-tel-input/build/css/intlTelInput.css';
import UtilService from './../services/UtilService';
import './MobileNumberField.css';
//TODO: migrate jquery functions
class MobileNumberField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {[this.props.name]: "", valid: true};
        this.props.errorSchema[this.props.name] = {errors: []};
    }

    componentDidMount() {
        console.log("input[name='"+this.props.name+"']");
        let telInput = $("input[name='"+this.props.name+"']");
        telInput.intlTelInput({
            utilsScript: util,
            initialCountry: "auto",
            geoIpLookup: function (callback) {
                UtilService.getIpInfo().then(resp=>{
                    let countryCode = (resp && resp.country) ? resp.country : "";
                    callback(countryCode);
                });
            }
        });

        telInput.blur(function () {
            this.setValue();
        }.bind(this));
    }

    onChange(event) {
       this.setValue();
    }
    setValue(){
        let telInput = $("input[name='"+this.props.name+"']");
        if ($.trim(telInput.val())) {
            let formatted = telInput.intlTelInput("getNumber", "intlTelInputUtils.numberFormat".RFC3966);
            formatted=formatted.replace(/\D/g,'');
            this.setState({
                [this.props.name]: formatted,
                valid: telInput.intlTelInput("isValidNumber")
            });
            this.props.onChange(this.state[this.props.name]);
        }
    }

    render() {
        return (<div className={`form-group mobile-number-widget ${this.state.valid ? "" : "field-error has-error" }`}>
                <label htmlFor="__mobile"
                       className="control-label ">{this.props.schema.title}{this.props.required ? " *" : ""}</label>
                <input id="__mobile" type="tel" name={this.props.name} onChange={this.onChange.bind(this)}
                       className="form-control"/>
            </div>
        );
    }
}

export default MobileNumberField;