import React, { Component } from 'react';
import SideBar from './../navigation/SideBar';
import { Switch, Route } from 'react-router-dom';
import RewardsPage from './../rewards/RewardsPage';
import CouponPage from './../coupon/CouponPage';
import CollectPage from './../collect/CollectPage';
import UsersPage from './../users/UsersPage';
import LeaderBoard from './../leader_board/LeaderBoard';
import { FormGroup, FormControl, ControlLabel, Form } from 'react-bootstrap';
import Constants from './../../Constants';
import './Home.css';
class Home extends Component {

    constructor(props) {
        super(props);
        this.state = { selectedBranch: '' };
    }

    onChangeSelectedBranch = (e) => {
        const branch = e.target.value;
        this.setState({ selectedBranch: branch });
        localStorage.setItem("branch", branch);
    }

    componentDidMount() {
        const currentBranch = localStorage.getItem("branch");
        if (currentBranch) {
            this.setState({ selectedBranch: currentBranch });
        }
    }


    render() {
        const { match, config = {}, scopes = {} } = this.props;

        const locations = config.locations ? config.locations.sort((a, b) => a.location.toLowerCase() - b.location.toLowerCase()) : [];

        return (
            <div className="d-flex home">

                {/* <NavigationBar /> */}
                <SideBar {...this.props} />
                <div className="content-wrapper">
                    <div className="text-right container">
                        <Form inline>
                            <FormGroup controlId="formControlsSelect">
                                <ControlLabel>Current Branch</ControlLabel>
                                <FormControl componentClass="select" placeholder="select" value={this.state.selectedBranch} onChange={this.onChangeSelectedBranch}>
                                    <option value="" disabled={true}>Select</option>
                                    {locations.map(({ number, location }) => {
                                        return (
                                            <option value={location} key={number}>{location}</option>
                                        )
                                    })}
                                </FormControl>
                            </FormGroup>
                        </Form>
                    </div>
                    <Switch>
                        {(scopes[Constants.USER_ACCESS_CONTROLS.MODULES.KLIP_REWARD_CLAIM.moduleId].read) && <Route path={`${match.path}/redeem`} component={CouponPage} />}
                        <Route path={`${match.path}/rewards`} component={RewardsPage} />
                        <Route path={`${match.path}/collect`} component={CollectPage} />

                        <Route path={`${match.path}/leaderboard`} component={LeaderBoard} />
                        {(scopes[Constants.USER_ACCESS_CONTROLS.MODULES.KLIP_USERS.moduleId].read) && <Route path={`${match.path}/users`} render={(props) => <UsersPage selectedBranch={this.state.selectedBranch} scopes={scopes} {...props} />} />}
                        {(scopes[Constants.USER_ACCESS_CONTROLS.MODULES.KLIP_USERS.moduleId].read) && <Route render={(props) => <UsersPage selectedBranch={this.state.selectedBranch} scopes={scopes} {...props} />} />}
                    </Switch>

                </div>
            </div>
        )
    }

}

export default Home;