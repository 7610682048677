/**
 * Created by madura on 12/21/16.
 */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Shoutout from '../../services/Shoutout';
import { notify } from 'react-notify-toast';
import FormView from './../forms/FormView';

class CreateUser extends Component {
    constructor() {
        super();
        this.state = { formSubmitted: false };
    }
    submit(result, callback) {

        let formDataObj = result.formData;

        Shoutout.registerUser(formDataObj).then(res => {
            callback(null, res);
            notify.show("Successfully created the user", "success");
            
        }, err => {
            notify.show("Can not register the user. Please try again", "error");
            callback("Can not register the user. Please try again");
        });
    }


    render() {
        const config = this.context.config || {};
        const form = config.registrationForm;
        return (
            <FormView form={form || {} } show={this.props.showModal} onCloseCallback={this.props.onCloseCallback} title="Register New Customer" onSubmit={this.submit} reloadMsg={true} />
        );

    }
}

CreateUser.contextTypes = {
    config: PropTypes.object
};

export default CreateUser;