/**
 * Created by madura on 1/9/17.
 */
import React from 'react';

class SeparatorField extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return (<div><hr/><legend>{this.props.schema.title}</legend></div>)
    }
}
export default SeparatorField;