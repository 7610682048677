import { fetchPost } from './CommonServiceUtils';

const BASE_URL = "https://api.getshoutout.com/loyaltyservice2/";



class ShoutoutLoyaltyService {

    static claimReward(voucherCode) {
        return fetchPost(BASE_URL + "reward/claim", { voucherCode });
    }

    static async redeemPoint(userId, activityData) {
        return fetchPost(BASE_URL + "pointsredeem", { userId, activityData });

    }
    static async redeemPointConfirm(userId, otpToken, otpCode, activityData) {
        return fetchPost(BASE_URL + "pointsredeem", { userId, activityData, otpValidation: { otpToken, otpCode } });
    }
}

export default ShoutoutLoyaltyService;