import React, { PureComponent } from 'react';
import { Modal, Button, FormControl, FormGroup, ControlLabel, Form, HelpBlock, Image } from 'react-bootstrap';
import Shoutout from './../../services/Shoutout';
import { notify } from 'react-notify-toast';
// import ShoutoutCouponService from '../../services/ShoutoutCouponService';

const initState = { otpCode: '', isSendingRequest: false, redeemSuccess: false };

class UserRedeemReward extends PureComponent {
    constructor() {
        super();
        this.state = { ...initState };
    }
    _onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    _onClose = () => {
        this.setState({ ...initState });
        this.props.onClose();
    }

    _onSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isSendingRequest: true });
        try {
            const { user, rewardData } = this.props;
            const rewardRedeemConfirmResponse = await Shoutout.redeemRewardConfirm(user.loyalty_id, rewardData.rewardId, rewardData.otpToken, this.state.otpCode, user.mobileNumber,this.props.selectedBranch);
            notify.hide();
            // if (rewardRedeemConfirmResponse.coupon_code) {
            //     await ShoutoutCouponService.redeemCoupon(rewardRedeemConfirmResponse.coupon_code);
            //     notify.show("Reward issued successfully.", "success");
            //     this.setState({ redeemSuccess: true });
            // } else {
            //     notify.show("Reward redeemed successfully.", "success");
            //     this._onClose();
            // }
            notify.show("Reward redeemed successfully.", "success");
            this.setState({ isSendingRequest: false, couponCode: rewardRedeemConfirmResponse.coupon_code, redeemSuccess: true });
            this.props.onRedeemed();
//   this._onClose();


        } catch (e) {
            this.setState({ isSendingRequest: false });
            notify.show("Invalid code. Please retry!", "error");
        }

    }


    render() {
        const { show, rewardData = { imageUrls: [] } } = this.props;

        return (<Modal show={show} onHide={this._onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Claim Reward</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    {rewardData && <div className="text-center">
                        <p>{rewardData.name}</p>

                        <Image src={rewardData.imageUrls[0]} width="100" />
                    </div>
                    }

                    {this.state.redeemSuccess ?
                        <p>Reward redeemed successfully. Ask customer to provide the coupon code.</p> :


                        <FormGroup controlId="coupon-code" >
                            <ControlLabel>Enter code:</ControlLabel>
                            <FormControl type="text" placeholder="ZBC123" name="otpCode" required onChange={this._onChange} value={this.state.otpCode} />
                            <HelpBlock>Ask from customer to provide the OTP code which is received to the customer's mobile number registered with the system.</HelpBlock>
                        </FormGroup>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.state.redeemSuccess ?
                        <Button bsStyle="info" type="button" bsSize="large" onClick={this._onClose} className="mr-2" disabled={this.state.isSendingRequest}>Close</Button>

                        :
                        <div>
                            <Button bsStyle="danger" type="button" bsSize="large" onClick={this._onClose} className="mr-2" 
                            disabled={this.state.isSendingRequest}>Cancel</Button>

                            <Button bsStyle="primary" type="submit" bsSize="large" onClick={this._onSubmit} 
                            disabled={this.state.isSendingRequest}>
                                {this.state.isSendingRequest ? "Please wait..." : "Continue"}</Button>
                        </div>
                    }
                </Modal.Footer>
            </Form>
        </Modal>);
    }
}

export default UserRedeemReward;