


module.exports = {
    USER_ACCESS_CONTROLS: {
        ACCESS_KEY: {
            DELETE: "delete",
            READ: "read",
            WRITE: "write"
        },
        MODULES: {
            KLIP_ADD_POINTS: {
                moduleId: "klippointscollect"
            },
            KLIP_REDEEM_POINTS: {
                moduleId: "klippointsredeem"
            },
            KLIP_USERS: {
                moduleId: "klipusers"
            },
            KLIP_REWARD_CLAIM: {
                moduleId: "kliprewardsclaim"
            },
            KLIP_REWARD_REDEEM: {
                moduleId: "kliprewardsredeem"
            }

        }
    }
}