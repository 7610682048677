/**
 * Created by madura on 12/5/16.
 */
import React, {Component} from 'react';
import Shoutout from './../../services/Shoutout';
import ReactDOM from 'react-dom';
import {Row, Col, Form, FormGroup, Button, ControlLabel, FormControl, Panel} from 'react-bootstrap';
class RewardLogin extends Component {
    constructor(props) {
        super(props);
        this.shoutout = new Shoutout();
        this.customerLogin = this.customerLogin.bind(this);
        this.customerLogout = this.customerLogout.bind(this);
    }

    customerLogin(event) {
        event.preventDefault();
        let id = ReactDOM.findDOMNode(this.refs.loyaltyId).value;
        let nic = ReactDOM.findDOMNode(this.refs.nic).value;
        this.props.onLogIn({"loyalty_id": id, "nic": nic});
    }

    customerLogout(event) {
        event.preventDefault();
            this.props.onLogout();
    }

    render() {

        return (
            <div>
                {!this.props.customer ? <Panel header={<p className="text-center">Login</p>} bsStyle="primary">
                    <Form horizontal onSubmit={this.customerLogin}>
                        <FormGroup controlId="loyaltyId">
                            <Col componentClass={ControlLabel} sm={3}>
                                Loyalty Id
                            </Col>
                            <Col sm={6}>
                                <FormControl type="text" placeholder="xxx-123" ref="loyaltyId"/>
                            </Col>
                        </FormGroup>

                        <FormGroup controlId="nic">
                            <Col componentClass={ControlLabel} sm={3}>
                                NIC
                            </Col>
                            <Col sm={6}>
                                <FormControl type="text" placeholder="900203290V" ref="nic"/>
                            </Col>
                        </FormGroup>
                        <FormGroup>

                            <Col smOffset={4} sm={4}>
                                <Button bsStyle="info" bsSize="large" type="submit" block>
                                    Login
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </Panel>
                    : <div>
                    <Panel header={<p className="text-center">Logout</p>} bsStyle="primary">
                        <Row>
                            <Col lg={6} xs={6} className="text-right">
                                Loyalty Id:
                            </Col>
                            <Col lg={6} xs={6}>
                                {this.props.customer.loyaltyId}
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={6} xs={6} className="text-right">
                                Name:
                            </Col>
                            <Col lg={6} xs={6}>
                                {this.props.customer.name}
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={6} xs={6} className="text-right">
                                Points:
                            </Col>
                            <Col lg={6} xs={6}>
                                {this.props.customer.points}
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={4} smOffset={4} className="text-center">
                                <Button bsStyle="info" bsSize="large" onClick={this.customerLogout} block>Logout</Button>
                            </Col>
                        </Row>
                    </Panel>
                </div>}
            </div>

        );
    }
}

export default RewardLogin;