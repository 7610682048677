import React, { Component } from 'react';
import {
    Row, 
    Col,
    Button,
    Form as BootstrapForm,
    FormGroup,
    Image,
    Modal,
} from 'react-bootstrap';
import Form from "react-jsonschema-form";

import SchemaField from "react-jsonschema-form/lib/components/fields/SchemaField";
import MobileNumberField from './../../fields/MobileNumberField';
import ReadableDateSelectorField from './../../fields/ReadableDateSelectorField';
import SeparatorField from './../../fields/SeparatorField';
import KeyHandler, { KEYPRESS } from 'react-key-handler';
import PropTypes from 'prop-types';
import './FormView.css';



const CustomSchemaField = function (props) {
    if (props.errorSchema && props.errorSchema.__errors && props.errorSchema.__errors.length) {
        props.errorSchema.__errors = ["Invalid " + props.name];
    }
    return (
        <div>
            <SchemaField {...props} />
        </div>
    );
};

const fields = {
    SchemaField: CustomSchemaField,
    MobileNumberField: MobileNumberField,
    ReadableDateSelectorField: ReadableDateSelectorField,
    SeparatorField: SeparatorField
};
const widgets = {};


class FormView extends Component {
    constructor() {
        super();
        this.state = { formSubmitted: false, isLoading: false, formData: {} };
        this.reload = this.reload.bind(this);
    }

    _onAfterSuccess(err, res) {
        if (err) {
            this.setState({ isLoading: false });
        } else {
            this.setState({ formSubmitted: true, isLoading: false, formData: {} });
        }
    }
    submit(result, event) {
        this.setState({ isLoading: true, formData: result.formData });
        this.props.onSubmit(result, this._onAfterSuccess.bind(this));
    }

    reload(event) {
        event.preventDefault();
        this.setState({ formSubmitted: false, formData: {} });
    }

    render() {

        const { form, onCloseCallback, show, title, reloadMsg } = this.props;



        let formElement;
        if (form && form.form) {
            const theme = form.metadata ? form.metadata.theme || {} : {};
            if (form.metadata && form.metadata.disabled) {
                formElement = (<div className="form-content">
                    {form.metadata.image ? <Image src={form.metadata.image} responsive /> : null}
                    <h4 className="text-info text-center">{form.metadata.disable_message ? form.metadata.disable_message : 'Sorry! Form is Closed'}</h4>
                </div>);
            } else {
                if (this.state.formSubmitted && reloadMsg) {
                    formElement = (<div className="form-content">
                        <BootstrapForm onSubmit={this.reload.bind(this)}
                            className="text-center">
                            <h1>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 52 52" xmlSpace="preserve" width="50" height="50">
	                            <path d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26   S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z" 
								data-original="#000000" className="active-path" fill="#25AE88"/>
	                            <path d="M38.252,15.336l-15.369,17.29l-9.259-7.407c-0.43-0.345-1.061-0.274-1.405,0.156c-0.345,0.432-0.275,1.061,0.156,1.406   l10,8C22.559,34.928,22.78,35,23,35c0.276,0,0.551-0.114,0.748-0.336l16-18c0.367-0.412,0.33-1.045-0.083-1.411   C39.251,14.885,38.62,14.922,38.252,15.336z" 
								data-original="#000000" className="active-path" fill="#25AE88"/>
                            </svg>
                               <br />
                            </h1>
                            <h4 className="submit-success-text">{form.metadata.text && form.metadata.text.submitSuccess ? form.metadata.text.submitSuccess : "Thank You! \n You've successfully registered."}</h4>
                            <br />
                            {form.metadata && form.metadata.type !== "single_response" &&
                                <FormGroup className="txt-center">
                                    <Button bsStyle="primary" bsSize="large" type="submit">Reload Form</Button>
                                    <br />
                                    <p className="text-muted">
                                        <small>Press</small>
                                        &nbsp;<strong>ENTER</strong></p>
                                    <KeyHandler keyEventName={KEYPRESS} keyCode={13}
                                        onKeyHandle={this.reload.bind(this)} />
                                </FormGroup>}
                        </BootstrapForm>
                    </div>
                    );
                } else {
                    formElement = (<div>
                        {form.metadata && form.metadata.image ?
                            <Image src={form.metadata.image} className="img-fluid" /> : null}
                        <br />
                        <div className="form-content">
                            <Form
                                schema={form.form.jsonschema} fields={fields}
                                uiSchema={form.form.uischema}
                                onSubmit={this.submit.bind(this)}
                                showErrorList={false} widgets={widgets}
                                formData={this.state.formData}
                            >
                                <div>{form.metadata && form.metadata.notes &&
                                    <small className="notes">{form.metadata.notes}<br /><br />
                                    </small>}</div>
                                <div className="text-center">
                                <Row>
                                    <Col  sm={6}>
                                        <Button type="submit" bsStyle="primary" bsSize="large" style={theme.submitBtn} disabled={this.state.isLoading}>&nbsp;&nbsp;{this.state.isLoading ? "Submitting..." : "Submit"}&nbsp;&nbsp;</Button>
                                    </Col>
                                    <Col sm={6}>
                                    <Button type="button" bsStyle="danger" bsSize="large" style={theme.warningBtn} disabled={this.state.isLoading} onClick={this.reload}>&nbsp;&nbsp;Reset&nbsp;&nbsp;</Button>
                                    </Col>
                                </Row>
                                       &nbsp;&nbsp;&nbsp;

            
                                </div>
                            </Form>
                        </div>
                    </div>
                    );
                }
            }
        } else {
            formElement = <p>No form found</p>
        }

        return (
            <Modal show={show} onHide={onCloseCallback}>
                <Modal.Header bsClass="modal-header" closeButton>
                    <Modal.Title bsClass="modal-title">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass="modal-body form-input-bottom-border">
                    {formElement}
                </Modal.Body>
            </Modal>
        );
    }

}

FormView.propTypes = {
    form: PropTypes.object.isRequired,
    onCloseCallback: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    reloadMsg: PropTypes.bool,
    onSubmit:PropTypes.func.isRequired
}
export default FormView;